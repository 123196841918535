<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card>
      <b-row class="mb-2">
        <b-col cols="12" md="8">
          <h4>Ürün Ekle / Oluştur</h4>
        </b-col>
        <b-col cols="6" md="2" sm="12" class="text-right">
          <b-button v-b-modal.modal-prevent-brand variant="warning" block>
            <feather-icon icon="PlusIcon" /> Marka Ekle
          </b-button>
        </b-col>
        <b-col cols="6" md="2" sm="12" class="text-right">
          <b-button v-b-modal.modal-prevent-closing variant="success" block>
            <feather-icon icon="PlusIcon" /> Ürün Ekle
          </b-button>
        </b-col>
      </b-row>

      <b-row class="pt-2">
        <b-col>
          <dx-data-grid
            id="dxDataGrid"
            :data-source="dataSource"
            :word-wrap-enabled="false"
            :remote-operations="false"
            column-resizing-mode="widget"
            :column-min-width="50"
            :show-borders="true"
            :show-row-lines="true"
            :row-alternation-enabled="false"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            height="650px"
          >
            <DxEditing
              :allow-updating="false"
              :allow-adding="false"
              :allow-deleting="false"
              mode="cell"
            ></DxEditing>
            <DxFilterRow :visible="true" />
            <DxFilterPanel :visible="true" />
            <DxFilterBuilderPopup :position="filterBuilderPopupPosition" />
            <DxHeaderFilter :visible="true" />

            <DxPaging :page-size="20" />
            <DxColumnFixing :enabled="true" />
            <DxColumnChooser :enabled="true" />
            <DxGroupPanel :visible="false" />
            <DxGrouping :auto-expand-all="false" />
            <DxExport :enabled="true" :allow-export-selected-data="true" />

            <DxSearchPanel :visible="true" :highlight-case-sensitive="true" />
            <DxPager
              :allowed-page-sizes="pageSizes"
              :show-page-size-selector="true"
            />
            <DxPaging :page-size="100" />
            <dx-column dataField="id" caption="ID" sortOrder="desc" />
            <dx-column dataField="barcode" caption="Barkod" />
            <dx-column dataField="name" caption="Ürün Adı" />
            <dx-column dataField="brand.name" caption="Marka" />
            <dx-column
              dataField="sellPrice"
              caption="Satış Fiyatı(TL)"
              format="#,##0.###"
              data-type="number"
            />
            <dx-column
              width="120px"
              caption="Aksiyon"
              cell-template="evrakTemplate"
            />

            <template #evrakTemplate="{ data }">
              <b-row>
                <b-col cols="12" md="6">
                  <b-button
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Düzenle"
                    @click="editPerm(data.data)"
                    size="sm"
                    variant="warning"
                    block
                    class="mb-1"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </b-col>
                <b-col cols="12" md="6">
                  <b-button
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Sil"
                    @click="deleteProduct(data.data)"
                    size="sm"
                    variant="danger"
                    block
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </dx-data-grid>
          <!-- <b-table
            :striped="false"
            :bordered="false"
            :hover="true"
            :busy="show"
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sticky-header="stickyHeader"
            responsive
            :filter="filter"
            class="custom-table"
          >
            <template #cell(actions)="data">
              <b-row>
                <b-col cols="12" md="6">
                  <b-button
                    @click="editPerm(data.item)"
                    size="sm"
                    variant="warning"
                    pill
                    block
                    class="mb-1"
                  >
                    <feather-icon icon="EditIcon" /> Düzelt
                  </b-button>
                </b-col>
                <b-col cols="12" md="6">
                  <b-button
                    @click="deleteProduct(data.item)"
                    pill
                    size="sm"
                    variant="danger"
                    block
                  >
                    <feather-icon icon="TrashIcon" /> Sil
                  </b-button>
                </b-col>
              </b-row>
            </template>

            <template #cell(createdAt)="data">
              {{ formatDate(data.item.createdAt) }}
            </template>
            <template #cell(sellPrice)="data">
              {{ formatCurrency(data.item.sellPrice) }}
            </template>
          </b-table> -->
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      title="Ürün Bilgileri"
      hide-footer
      size="lg"
      size-sm
    >
      <form ref="form" @submit.stop.prevent="add">
        <b-form-group label="Marka Bilgisi" label-for="name-select">
          <b-form-select
            v-model="product.brandId"
            :options="brands"
            size="sm"
          />
        </b-form-group>
        <b-form-group label="Ürün Adı" label-for="name-input">
          <b-form-input id="name-input" v-model="product.name" size="sm" />
        </b-form-group>
        <b-form-group label="Ürün Açıklaması" label-for="name-input">
          <b-form-input
            id="name-input"
            v-model="product.description"
            size="sm"
          />
        </b-form-group>
        <b-form-group label="Ürün Kodu">
          <b-form-input size="sm" id="name-input" v-model="product.barcode" />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox id="barcode-check" v-model="product.codeGenerate">
            Barkodu Yoksa işaretle
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          label="Satış Fiyatı"
          label-for="name-input"
          invalid-feedback="Alış Fiyatı Boş Olamaz"
        >
          <b-form-input
            size="sm"
            id="name-input"
            type="number"
            v-model="product.sellPrice"
          />
        </b-form-group>
        <b-form-group label="Birim Bilgisi" label-for="name-select">
          <b-form-select
            size="sm"
            v-model="product.unitTypeId"
            :options="unitTypes"
          />
        </b-form-group>
        <b-button class="mt-2" block variant="danger" @click="add"
          >Ekle</b-button
        >
        <b-button class="mt-2" block @click="hideModal">iptal</b-button>
      </form>
    </b-modal>

    <b-modal
      id="modal-prevent-update"
      ref="my-modal"
      title="Ürün Bilgileri"
      ok-title="Güncelle"
      cancel-title="İptal"
      cancel-variant="outline-secondary"
      @ok="update"
      size="lg"
      size-sm
    >
      <form ref="form" @submit.stop.prevent="add">
        <b-form-group label="Marka Bilgisi" label-for="name-select">
          <b-form-select
            v-model="productUpdate.brandId"
            :options="brands"
            size="sm"
          />
        </b-form-group>
        <b-form-group label="Ürün Adı" label-for="name-input">
          <b-form-input
            id="name-input"
            v-model="productUpdate.name"
            size="sm"
            disabled
          />
        </b-form-group>
        <b-form-group label="Ürün Açıklaması" label-for="name-input">
          <b-form-input
            id="name-input"
            v-model="productUpdate.description"
            size="sm"
          />
        </b-form-group>
        <b-form-group label="Ürün Kodu">
          <b-form-input
            size="sm"
            id="name-input"
            v-model="productUpdate.barcode"
            disabled
          />
        </b-form-group>

        <b-form-group
          label="Satış Fiyatı"
          label-for="name-input"
          invalid-feedback="Alış Fiyatı Boş Olamaz"
        >
          <b-form-input
            size="sm"
            id="name-input"
            type="number"
            v-model="productUpdate.sellPrice"
          />
        </b-form-group>
      </form>
    </b-modal>

    <b-modal
      id="modal-prevent-brand"
      ref="my-modal"
      title="Marka Ekle"
      ok-title="Ekle"
      cancel-title="İptal"
      cancel-variant="outline-secondary"
      @ok="addBrand"
      size="lg"
      size-sm
    >
      <form ref="form" @submit.stop.prevent="add">
        <b-form-group label="Marka Adı" label-for="name-input">
          <b-form-input size="sm" id="name-input" v-model="brandName" />
        </b-form-group>
      </form>
    </b-modal>
  </b-overlay>
</template>
<script>
import {
  BCard,
  BCardText,
  BLink,
  BTable,
  BButton,
  BOverlay,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BModal,
  VBModal,
  BFormCheckbox,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import {
  DxDataGrid,
  DxColumn,
  DxExport,
  DxSelection,
  DxGroupPanel,
  DxGrouping,
  DxFilterRow,
  DxLoadPanel,
  DxMasterDetail,
  DxColumnFixing,
  DxStateStoring,
  DxHeaderFilter,
  DxFilterPanel,
  DxColumnChooser,
  DxPager,
  DxEditing,
  DxPopup,
  DxLookup,
  DxForm,
  DxPaging,
  DxSearchPanel,
  DxFilterBuilderPopup,
  DxScrolling,
  DxRequiredRule,
  DxEmailRule,
  DxPatternRule,
  DxAsyncRule,
} from "devextreme-vue/data-grid";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BTable,
    BButton,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BModal,
    ToastificationContent,
    BFormCheckbox,
    DxDataGrid,
    DxColumn,
    DxExport,
    DxSelection,
    DxGroupPanel,
    DxGrouping,
    DxFilterRow,
    DxLoadPanel,
    DxMasterDetail,
    DxColumnFixing,
    DxStateStoring,
    DxHeaderFilter,
    DxFilterPanel,
    DxColumnChooser,
    DxPager,
    DxEditing,
    DxPopup,
    DxLookup,
    DxForm,
    DxPaging,
    DxSearchPanel,
    DxFilterBuilderPopup,
    DxScrolling,
    DxRequiredRule,
    DxEmailRule,
    DxPatternRule,
    DxAsyncRule,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      pageSizes: [50, 100, 200, 500],
      filterBuilderPopupPosition: {
        of: window,
        at: "top",
        my: "top",
        offset: { y: 10 },
      },
      dataSource: new DataSource({
        store: new ArrayStore({
          data: null,
        }),
      }),
      stickyHeader: false,
      show: false,
      perPage: 8,
      pageOptions: [8, 10, 12],
      currentPage: 1,
      filter: null,
      product: {
        name: "",
        description: "",
        barcode: "",
        brandId: null,
        unitTypeId: 0,
        sellPrice: 0,
        codeGenerate: false,
      },
      productUpdate: {
        id: null,
        name: "",
        description: "",
        barcode: "",
        brandId: null,
        unitTypeId: 0,
        sellPrice: 0,
        codeGenerate: false,
      },
      nameState: null,
      descriptionState: null,

      brandState: null,
      unitState: null,
      priceState: null,
      brands: [],
      brandName: "",
      unitTypes: [],
      items: [],
      fields: [
        { key: "barcode", label: "Barkod", sortable: true },
        { key: "name", label: "Ürün Adı", sortable: true },
        {
          key: "brand.name",
          label: "Marka",
          sortable: true,
        },
        {
          key: "sellPrice",
          label: "Satış Fiyatı",
          sortable: true,
        },
        { key: "actions", label: "Aksiyon" },
      ],
      boxOne: "",
    };
  },
  computed: {},
  created() {
    this.getBrand();
    this.getUnitType();
    this.getProduct();
  },
  methods: {
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    formatCurrency(amount) {
      const options = {
        style: "currency",
        currency: "TRY",
        currencyDisplay: "symbol", // Use the currency symbol
      };
      const formatter = new Intl.NumberFormat("tr-TR", options);
      return formatter.format(amount);
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      const date = new Date(dateString);
      return date.toLocaleDateString("tr-TR", options);
    },
    async getProduct() {
      this.show = true;
      this.dataSource = new DataSource({
        store: new ArrayStore({
          data: null,
        }),
      });
      this.$http
        .get("Products")
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            this.items = response.data;
            this.dataSource = new DataSource({
              store: new ArrayStore({
                data: this.items,
              }),
            });
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async getBrand() {
      this.show = true;
      this.$http
        .get("brands")
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            var satirlar = response.data;
            this.brands = [];
            for (var i = 0; i < satirlar.length; i++) {
              var model = {
                value: satirlar[i].id,
                text: satirlar[i].name,
              };
              this.brands.push(model);
            }
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async getUnitType() {
      this.show = true;
      this.$http
        .get("unitTypes")
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            var satirlar = response.data;
            for (var i = 0; i < satirlar.length; i++) {
              var model = {
                value: satirlar[i].id,
                text: satirlar[i].code,
              };
              this.unitTypes.push(model);
            }
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async deleteProduct(data) {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm("Ürün Silmek İstediğinize Emin misiniz ?", {
          title: "Dikkat",
          size: "sm",
          okVariant: "primary",
          okTitle: "Evet",
          cancelTitle: "Hayır",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: false,
        })
        .then((value) => {
          this.boxOne = value;
          if (value == true) {
            this.show = true;
            this.$http
              .delete(`products?id=${data.id}`)
              .then((response) => {
                if (response.status === 200) {
                  // If successful, update the table data by removing the deleted item
                  this.getProduct();
                  this.show = false;

                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Başarılı`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `Silme İşlemi Başarılı..`,
                    },
                  });
                }
              })
              .catch((error) => {
                this.show = false;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Hata`,
                    icon: "CoffeeIcon",
                    variant: "danger",
                    text: error.response.data.message,
                  },
                });
              });
          }
        });
    },
    async add() {
      if (this.product.sellPrice == null || this.product.sellPrice == "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Satış Fiyatı Boş Olamaz..",
          },
        });
        return;
      }
      if (this.product.name == null || this.product.name == "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Ürün Adı Boş Olamaz..",
          },
        });
        return;
      }
      if (this.product.description == null || this.product.description == "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Açıklama Boş Olamaz..",
          },
        });
        return;
      }
      if (
        this.product.brandId == null ||
        this.product.brandId == "" ||
        this.product.brandId == 0
      ) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Marka Boş Olamaz..",
          },
        });
        return;
      }
      if (
        this.product.unitTypeId == null ||
        this.product.unitTypeId == "" ||
        this.product.unitTypeId == 0
      ) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Birim Boş Olamaz..",
          },
        });
        return;
      }
      if (this.product.codeGenerate == false) {
        if (this.product.barcode == null || this.product.barcode == "") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: "Barkod Boş Olamaz..",
            },
          });
          return;
        }
      }
      this.$http
        .post("Products", this.product)
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            this.getProduct();
            this.$bvModal.hide("modal-prevent-closing");
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async update() {
      if (
        this.productUpdate.sellPrice == null ||
        this.productUpdate.sellPrice == ""
      ) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Satış Fiyatı Boş Olamaz..",
          },
        });
        return;
      }
      if (
        this.productUpdate.description == null ||
        this.productUpdate.description == ""
      ) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Açıklama Boş Olamaz..",
          },
        });
        return;
      }
      this.$http
        .put("Products", this.productUpdate)
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            this.$bvModal.hide("modal-prevent-update");
            this.getProduct();
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async addBrand() {
      if (this.brandName == null || this.brandName == "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Marka Boş Olamaz..",
          },
        });
        return;
      }
      var sendModel = { name: this.brandName };

      this.$http
        .post("brands", sendModel)
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            this.getBrand();
            this.brandName = "";
            this.$bvModal.hide("modal-prevent-brand");
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async editPerm(data) {
      this.productUpdate.id = data.id;
      this.productUpdate.name = data.name;
      this.productUpdate.description = data.description;
      this.productUpdate.barcode = data.barcode;
      this.productUpdate.brandId = data.brandId;
      this.productUpdate.unitTypeId = data.unitTypeId;
      this.productUpdate.sellPrice = data.sellPrice;
      this.productUpdate.codeGenerate = data.codeGenerate;
      this.$bvModal.show("modal-prevent-update");
    },
  },
};
</script>

<style>
.custom-width-actions {
  width: 180px; /* Set the width of the Actions column */
} /* Example media query for smaller screens */
</style>
